import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { Progress, Table } from "antd";
import { Col, Row, Spinner, Toast } from "reactstrap";
import {
  getFormSyncRecordsAPI,
  importRecordsAPI,
  searchSyncRecordsAPI,
  syncRecordsAPI,
} from "../../../../../helpers/service_helper";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../../Components/Common/Loader";
import JsonView from "react18-json-view";
import { t } from "i18next";
import ModalContainer from "../../../../../Components/Common/ModalContainer";

export default function DynamicSearchForm({ action, onBack, dataFormSearch }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loadingImport, setLoadingImport] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const [dataSearch, setDataSearch] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [valueRawData, setValueRawData] = useState({});
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Raw Data",
      dataIndex: "raw_data",
      key: "raw_data",
      render: (value) => (
        <div>
          <ButtonTheme
            loadShowText={true}
            className="btn btn-ghost-primary"
            icon="ri-eye-line me-2"
            onClick={async () => {
              setIsOpen(true);
              setValueRawData(value);
            }}
          >
            {t("View Detail")}
          </ButtonTheme>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Row>
        <ModalContainer
          isOpen={isOpen}
          title={"View raw data"}
          scrollable={true}
          toggle={() => {
            setIsOpen(false);
          }}
        >
          <JsonView src={valueRawData} collapsed={2} enableClipboard={true} />
        </ModalContainer>
        <Col lg={4}>
          <DynamicFormSearch
            action={action}
            id={id}
            onSubmit={async (values) => {
              setLoading(true);
              try {
                const newValue = {};
                Object.keys(values).forEach((item) => {
                  if (values[item]) {
                    newValue[item] = values[item];
                  }
                });
                const response = await searchSyncRecordsAPI(id, {
                  action_type: action,
                  params: {
                    ...newValue,
                  },
                });
                setListTable(response?.data ?? []);
                setDataSearch(newValue);
                setLoading(false);
              } catch (error) {
                setLoading(false);
                toast.error(error);
              }
            }}
            onBack={onBack}
            dataFormSearch={dataFormSearch}
          />
        </Col>
        <Col lg={8}>
          {progress > 0 && <Progress percent={progress} status="active" />}
          <div className="hstack gap-2 justify-content-end mt-3">
            {listTable.length > 0 && (
              <>
                <ButtonTheme
                  className="btn btn-outline-primary mb-3"
                  onClick={async () => {
                    if (Object.keys(dataSearch)) {
                      setLoadingImport(true);
                      try {
                        await importRecordsAPI(
                          id,
                          {
                            action_type: action,
                            params: {
                              ...dataSearch,
                            },
                          },
                          {
                            onUploadProgress: (progressEvent) => {
                              if (progressEvent.total) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress(percentCompleted);
                              }
                            },
                          },
                        );
                        setLoadingImport(false);
                        toast.success("Import Record Success");
                      } catch (error) {
                        setLoadingImport(false);
                        toast.error(error);
                      }
                    } else {
                      toast.error("error");
                    }
                  }}
                  loadShowText={true}
                  loading={loadingImport}
                  disabled={loadingImport || loadingSync}
                >
                  Import Record
                </ButtonTheme>
                <ButtonTheme
                  className="btn btn-outline-primary mb-3"
                  onClick={async () => {
                    if (Object.keys(dataSearch)) {
                      setLoadingSync(true);
                      try {
                        await syncRecordsAPI(
                          id,
                          {
                            action_type: action,
                            params: {
                              ...dataSearch,
                            },
                          },
                          {
                            onUploadProgress: (progressEvent) => {
                              if (progressEvent.total) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress(percentCompleted);
                              }
                            },
                          },
                        );
                        setLoadingSync(false);
                        toast.success("Sync Record Success");
                      } catch (error) {
                        setLoadingSync(false);
                        toast.error(error);
                      }
                    } else {
                      toast.error("error");
                    }
                  }}
                  loadShowText={true}
                  loading={loadingSync}
                  disabled={loadingImport || loadingSync}
                >
                  Sync Record
                </ButtonTheme>
              </>
            )}
          </div>
          <Table
            dataSource={listTable}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            scroll={{
              y: "50vh",
            }}
            rowKey={(row) => row?.id}
            sortDirections={["DESC", "ASC"]}
            showSorterTooltip={false}
            loading={{
              indicator: (
                <div>
                  <Spinner color="primary" />
                </div>
              ),
              spinning: loading,
            }}
          />
          {}
        </Col>
      </Row>
    </div>
  );
}

const DynamicFormSearch = ({ action, id, onSubmit = () => {}, onBack = () => {}, dataFormSearch = [] }) => {
  const initialFormValues = useMemo(
    () =>
      dataFormSearch.reduce((acc, item) => {
        acc[item.key] = item.type === "Boolean" ? false : "";
        return acc;
      }, {}),
    [dataFormSearch],
  );
  return (
    <Formik initialValues={initialFormValues} onSubmit={(values) => onSubmit(values)}>
      {({ values, errors, setFieldValue }) => (
        <Form>
          {dataFormSearch.map((item, index) => {
            if (item.type === "Number") {
              return <InputTheme type="number" name={item.key} label={item.key} key={item.key} />;
            } else if (item.type === "String") {
              return <InputTheme key={item.key} name={item.key} label={item.key} />;
            } else if (item.type === "DateTime") {
              return <InputTheme key={item.key} type="date" name={item.key} label={item.key} />;
            } else return <InputTheme key={item.key} type="checkbox" name={item.key} label={item.key} />;
          })}
          <div className="hstack gap-2 justify-content-end mt-3">
            <ButtonTheme type="submit" className="btn btn-outline-primary" onClick={onBack}>
              Back
            </ButtonTheme>
            <ButtonTheme type="submit" className="btn btn-outline-primary">
              Search
            </ButtonTheme>
          </div>
        </Form>
      )}
    </Formik>
  );
};
