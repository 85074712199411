export const Actions = {
  EMPTY: "",
  LIST: "LIST",
  GET: "GET",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  CREATE: "CREATE",
  OTHERS: "OTHERS",
};

export const PurchaseOrderActions = {
  EMPTY: "",
  CONFIRM: "confirm",
  PAY: "pay",
  COMPLETE: "complete",
  RETURN: "return",
  IMPORT: "import",
  UPDATE: "update",
  CANCEL: "cancel",
};

export const PurchaseOrderStatuses = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  SHIPPING: "SHIPPING",
  RETURNED: "RETURNED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const PurchaseOrderStatusOptions = [
  { id: "DRAFT", name: "Draft" },
  { id: "PENDING", name: "Pending" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "RETURNED", name: "Returned" },
  { id: "COMPLETED", name: "Completed" },
  { id: "CANCELLED", name: "Cancelled" },
];

export const OrderActions = {
  EMPTY: "",
  CONFIRM: "confirm",
  CANCEL: "cancel",
  REQUEST_CANCEL: "request_cancel",
  REJECT_CANCEL: "reject_cancel",
  PAY: "pay",
  PACK: "pack",
  READY: "ready",
  SHIP: "ship",
  DELIVERED: "delivered",
  DELIVERED_RETURN: "delivered_return",
};

export const OrderStatusOptions = [
  { id: "DRAFT", name: "Draft" },
  { id: "PENDING", name: "Pending" },
  { id: "AWAIT_PACKING", name: "Await Packing" },
  { id: "PACKING", name: "Packing" },
  { id: "PARTIAL_PACKING", name: "Partial packing" },
  { id: "READY", name: "Ready" },
  { id: "PARTIAL_READY", name: "Partial ready" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "PARTIAL_SHIPPING", name: "Partial shipping" },
  { id: "DELIVERED", name: "Delivered" },
  { id: "PARTIAL_DELIVERED", name: "Partial delivered" },
  { id: "RETURNING", name: "Returning" },
  { id: "PARTIAL_RETURNING", name: "Partial returning" },
  { id: "RETURNED", name: "Returned" },
  { id: "PARTIAL_RETURNED", name: "Partial returned" },
  { id: "COMPLETED", name: "Completed" },
  { id: "PARTIAL_COMPLETED", name: "Partial completed" },
  { id: "IN_CANCEL", name: "In cancel" },
  { id: "CANCELLED", name: "Cancelled" },
  { id: "SYNC_ERROR", name: "Sync error" },
];

export const DefaultOrderSteps = [
  { id: "DRAFT", name: "Draft" },
  { id: "PENDING", name: "Pending" },
  { id: "AWAIT_PACKING", name: "Await Packing" },
  { id: "PACKING", name: "Packing" },
  { id: "READY", name: "Ready" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "DELIVERED", name: "Delivered" },
  { id: "RETURNING", name: "Returning" },
  { id: "RETURNED", name: "Returned" },
  { id: "COMPLETED", name: "Completed" },
];
export const OrderStatuses = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  AWAIT_PACKING: "AWAIT_PACKING",
  PACKING: "PACKING",
  PARTIAL_PACKING: "PARTIAL_PACKING",
  READY: "READY",
  PARTIAL_READY: "PARTIAL_READY",
  SHIPPING: "SHIPPING",
  PARTIAL_SHIPPING: "PARTIAL_SHIPPING",
  DELIVERED: "DELIVERED",
  PARTIAL_DELIVERED: "PARTIAL_DELIVERED",
  RETURNING: "RETURNING",
  PARTIAL_RETURNING: "PARTIAL_RETURNING",
  RETURNED: "RETURNED",
  PARTIAL_RETURNED: "PARTIAL_RETURNED",
  COMPLETED: "COMPLETED",
  PARTIAL_COMPLETED: "PARTIAL_COMPLETED",
  CANCELLED: "CANCELLED",
  IN_CANCEL: "IN_CANCEL",
  SYNC_ERROR: "SYNC_ERROR",
};

export const ReturnOrderStatuses = {
  NOT_RECEIVED: "NOT_RECEIVED",
  RECEIVED: "RECEIVED",
  CANCELLED: "CANCELLED",
};

export const ReturnOrderOptions = [
  { id: "NOT_RECEIVED", name: "Not received" },
  { id: "RECEIVED", name: "Received" },
  { id: "CANCELLED", name: "Cancelled" },
];

export const OrderTypes = {
  POS: "POS",
  IMPORTED: "IMPORTED",
  ECOMMERCE: "ECOMMERCE",
};

export const OrderTypeOptions = [
  { id: "POS", name: "POS" },
  { id: "IMPORTED", name: "Imported" },
  { id: "ECOMMERCE", name: "E-Commerce" },
];

export const ReturnOrderReasonStatuses = {
  OTHER: "OTHER",
  DEFECT: "DEFECT",
  DAMAGED: "DAMAGED",
};

export const ReturnOrderReasonOptions = [
  { id: "OTHER", name: "Other reason" },
  { id: "DEFECT", name: "Defect" },
  { id: "DAMAGED", name: "Damaged" },
];

export const PaymentStatuses = {
  UNPAID: "UNPAID",
  PARTIAL_PAID: "PARTIAL_PAID",
  COD: "COD",
  PENDING_ESCROW: "PENDING_ESCROW",
  PAID: "PAID",
  PARTIAL_REFUNDED: "PARTIAL_REFUNDED",
  REFUNDED: "REFUNDED",
  VOIDED: "VOIDED",
};

export const PaymentStatusOptions = [
  { id: "UNPAID", name: "Unpaid" },
  { id: "PARTIAL_PAID", name: "Partial paid" },
  { id: "COD", name: "COD" },
  { id: "PENDING_ESCROW", name: "Pending escrow" },
  { id: "PAID", name: "Paid" },
  { id: "PARTIAL_REFUNDED", name: "Partial refunded" },
  { id: "REFUNDED", name: "Refunded" },
  { id: "VOIDED", name: "Voided" },
];

export const DefaultPaymentSteps = [
  { id: "UNPAID", name: "Unpaid" },
  { id: "COD", name: "COD" },
  { id: "PENDING_ESCROW", name: "Pending escrow" },
  { id: "PAID", name: "Paid" },
  { id: "REFUNDED", name: "Refunded" },
];

export const PackageStatuses = {
  PENDING: "PENDING",
  PACKING: "PACKING",
  READY: "READY",
  SHIPPING: "SHIPPING",
  DELIVERED: "DELIVERED",
  RETURNING: "RETURNING",
  RETURNED: "RETURNED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const PackageStatusOptions = [
  { id: "PENDING", name: "Pending" },
  { id: "PACKING", name: "Packing" },
  { id: "READY", name: "Ready" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "DELIVERED", name: "Delivered" },
  { id: "RETURNING", name: "Returning" },
  { id: "RETURNED", name: "Returned" },
  { id: "COMPLETED", name: "Completed" },
  { id: "CANCELLED", name: "Cancelled" },
];

export const DATA_PRINT = {
  store_name: "NoLi Handmade",
  store_address: "Số 41 ngõ 63 Phố Ô Đồng Lầm, đường ven Hồ Ba Mẫu, Phương Liên, Đống Đa, Hà Nội",
  line_variant_code: "VR01",
  line_variant_name: "Áo thun màu tím",
  line_quantity: 1,
  bar_code: "SP00001",
  qr_code: "100000",
  logo_shop: "https://onexapis.s3.amazonaws.com/onexapis-B-16-9-2.png",
};

export const DiscountTypeOptions = [
  { id: "BY_ORDER_TOTAL", name: "Discount by order total" },
  { id: "BY_PRODUCT", name: "Discount by each product" },
  { id: "PRODUCT_BY_ORDER_TOTAL", name: "Discount product by order total" },
  { id: "BY_QUANTITY", name: "Discount by quantity" },
  { id: "BY_PURCHASE_PRODUCT", name: "Discount by purchased product" },
  { id: "GIFT_BY_ORDER_TOTAL", name: "Gift by order total" },
  { id: "GIFT_BY_PURCHASE_PRODUCT", name: "Gift by purchased product" },
];
export const DiscountTypes = {
  BY_ORDER_TOTAL: "BY_ORDER_TOTAL",
  BY_PRODUCT: "BY_PRODUCT",
  PRODUCT_BY_ORDER_TOTAL: "PRODUCT_BY_ORDER_TOTAL",
  BY_QUANTITY: "BY_QUANTITY",
  BY_PURCHASE_PRODUCT: "BY_PURCHASE_PRODUCT",
  GIFT_BY_ORDER_TOTAL: "GIFT_BY_ORDER_TOTAL",
  GIFT_BY_PURCHASE_PRODUCT: "GIFT_BY_PURCHASE_PRODUCT",
};

export const DayOfWeekOptions = [
  { id: 0, name: "Monday" },
  { id: 1, name: "Tuesday" },
  { id: 2, name: "Wednesday" },
  { id: 3, name: "Thursday" },
  { id: 4, name: "Friday" },
  { id: 5, name: "Saturday" },
  { id: 6, name: "Sunday" },
];

export const MonthOptions = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const EffectiveProductOptions = [
  { id: "PRODUCTS", name: "Products" },
  { id: "VARIANTS", name: "Variants" },
  { id: "CATEGORIES", name: "Categories" },
  { id: "BRANDS", name: "Brands" },
];

export const EffectiveProduct = {
  PRODUCTS: "PRODUCTS",
  VARIANTS: "VARIANTS",
  CATEGORIES: "CATEGORIES",
  BRANDS: "BRANDS",
};

export const DiscountStatuses = {
  DRAFT: "DRAFT",
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  CANCELED: "CANCELED",
};

export const DiscountStatusOptions = [
  { id: "DRAFT", name: "Draft" },
  { id: "RUNNING", name: "Running" },
  { id: "PAUSED", name: "Paused" },
  { id: "CANCELED", name: "Canceled" },
];

export const DiscountAmountType = {
  PERCENT: "PERCENT",
  VALUE: "VALUE",
};

export const ImportStatusOptions = [
  { id: "PROCESSING", name: "Processing" },
  { id: "FAILED", name: "Failed" },
  { id: "COMPLETED", name: "Completed" },
];

export const ImportTypeOptions = [
  { id: "product", name: "Product" },
  { id: "order", name: "Order" },
  { id: "stock_adjustment", name: "Stock adjustment" },
  { id: "purchase_order", name: "Purchase order" },
  { id: "customer", name: "Customer" },
  { id: "supplier", name: "Supplier" },
  { id: "return_order", name: "Return Order" },
];

export const ImportTypes = {
  product: "Product",
  order: "Order",
  stock_adjustment: "Stock adjustment",
  purchase_order: "Purchase order",
  customer: "Customer",
  supplier: "Supplier",
  return_order: "Return Order",
};

export const RecordResponseTypeOptions = [
  { id: "success", name: "Success" },
  { id: "error", name: "Error" },
  { id: "warning", name: "Warning" },
];

export const RecordTypeOptions = [
  { id: "product", name: "Product" },
  { id: "stock_adjustment", name: "Stock adjustment" },
  { id: "order", name: "Order" },
  { id: "return_order", name: "Return Order" },
  { id: "purchase_order", name: "Purchase order" },
  { id: "customer", name: "Customer" },
  { id: "inventory", name: "Inventory" },
];

export const RecordTypes = {
  product: "Product",
  stock_adjustment: "Stock adjustment",
  order: "Order",
  return_order: "Return Order",
  purchase_order: "Purchase order",
  customer: "Customer",
  inventory: "Inventory",
};

export const EventSourceOption = [
  { id: "webhook", name: "Webhook" },
  { id: "scheduler", name: "Scheduler" },
  { id: "import_record_api", name: "Import Record API" },
  { id: "sync_filter_api", name: "Sync Filter API" },
  { id: "sync_record_api", name: "Sync Record API" },
];

export const ActionGroupOptions = [
  { id: "order", name: "Order" },
  { id: "product", name: "Product" },
  { id: "purchase_order", name: "Purchase Order" },
  { id: "return_order", name: "Return Order" },
  { id: "inventory", name: "Inventory" },
  { id: "image", name: "Image" },
  { id: "webhook", name: "Webhook" },
  { id: "unknown", name: "Unknown" },
];

export const ActionTypeOptions = [
  { id: "get_order", name: "Get Order" },
  { id: "sync_order", name: "Sync Order" },
  { id: "get_purchase_order", name: "Get Purchase Order" },
  { id: "sync_purchase_order", name: "Sync Purchase Order" },
  { id: "get_product", name: "Get Product" },
  { id: "sync_product", name: "Sync Product" },
  { id: "get_inventory", name: "Get Inventory" },
  { id: "sync_inventory", name: "Sync Inventory" },
  { id: "get_return_order", name: "Get Return Order" },
  { id: "sync_return_order", name: "Sync Return Order" },
  { id: "sync_image", name: "Sync Image" },
  { id: "webhook", name: "Webhook" },
];

export const FetchEventStatusOptions = [
  { id: "PENDING", name: "Pending" },
  { id: "PROCESSING", name: "Processing" },
  { id: "FAILED", name: "Failed" },
  { id: "COMPLETED", name: "Completed" },
];

export const FetchEventStatuses = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
  COMPLETED: "COMPLETED",
};

export const SyncEventStatusOptions = [
  { id: "FETCHED", name: "Fetched" },
  { id: "TRANSFORMED", name: "Transformed" },
  { id: "PUBLISHED", name: "Published" },
  { id: "ERROR", name: "Error" },
  { id: "SKIPPED", name: "Skipped" },
  { id: "COMPLETED", name: "Completed" },
];

export const SyncEventStatuses = {
  FETCHED: "FETCHED",
  TRANSFORMED: "TRANSFORMED",
  PUBLISHED: "PUBLISHED",
  ERROR: "ERROR",
  SKIPPED: "SKIPPED",
  COMPLETED: "COMPLETED",
};

export const ActionType = {
  get_order: "Get Order",
  sync_order: "Sync Order",
  get_product: "Get Product",
  sync_product: "Sync Product",
  get_purchase_order: "Get Purchase Order",
  sync_purchase_order: "Sync Purchase Order",
  get_inventory: "Get Inventory",
  sync_inventory: "Sync Inventory",
  get_return_order: "Get Return Order",
  sync_return_order: "Sync Return Order",
  sync_image: "Sync Image",
  webhook: "Webhook",
};

export const RecordResponseCodeOptions = [
  { id: "error_not_found", name: "Error not found" },
  { id: "error_product_not_found", name: "Error product not found" },
  { id: "error_out_of_stock", name: "Error out of stock" },
  { id: "error_sku_existed", name: "Error sku existed" },
  { id: "error_location_not_found", name: "Error location not found" },
  { id: "error_price_invalid", name: "Error price invalid" },
  { id: "error_purchase_order_not_found", name: "Error purchase order not found" },
  { id: "error_status_invalid", name: "Error status invalid" },
];

export const ReservedVoucherOptions = [
  { id: "PURCHASE_ORDER_VOUCHER", name: "Purchase order voucher" },
  { id: "ORDER_PAYMENT_VOUCHER", name: "Order payment voucher" },
  { id: "RETURN_ORDER_VOUCHER", name: "Return order voucher" },
];

export const ReservedVoucher = {
  PURCHASE_ORDER_VOUCHER: "PURCHASE_ORDER_VOUCHER",
  ORDER_PAYMENT_VOUCHER: "ORDER_PAYMENT_VOUCHER",
  RETURN_ORDER_VOUCHER: "RETURN_ORDER_VOUCHER",
};

export const TransactionVoucherTypeOptions = [
  { id: "EXPENSE", name: "Expense" },
  { id: "PAYMENT", name: "Payment" },
];

export const PeriodOptions = [
  { id: "week", name: "Week" },
  { id: "month", name: "Month" },
  { id: "year", name: "Year" },
];

export const StockAdjustmentReasonOptions = [
  { id: "OTHER", name: "Other reason" },
  { id: "DEPRECIATION", name: "Depreciation" },
  { id: "DAMAGED", name: "Damaged" },
  { id: "RETURN", name: "Return" },
  { id: "RELOCATE", name: "Relocate" },
  { id: "PRODUCTION", name: "Production" },
];

export const StockAdjustmentReasons = {
  OTHER: "OTHER",
  DEPRECIATION: "DEPRECIATION",
  DAMAGED: "DAMAGED",
  RETURN: "RETURN",
  RELOCATE: "RELOCATE",
  PRODUCTION: "PRODUCTION",
};

export const StockAdjustmentStatuses = {
  AWAIT_CHECKING: "AWAIT_CHECKING",
  BALANCED: "BALANCED",
  DELETED: "DELETED",
};

export const StockAdjustmentStatusOptions = [
  { id: "AWAIT_CHECKING", name: "Await checking" },
  { id: "BALANCED", name: "Balanced" },
  { id: "DELETED", name: "Deleted" },
];

export const StockRelocateStatuses = {
  AWAIT_DELIVERY: "AWAIT_DELIVERY",
  DELIVERING: "DELIVERING",
  RECEIVED: "RECEIVED",
  CANCELLED: "CANCELLED",
};

export const StockRelocateStatusOptions = [
  { id: "AWAIT_DELIVERY", name: "Await delivery" },
  { id: "DELIVERING", name: "Delivering" },
  { id: "RECEIVED", name: "Received" },
  { id: "CANCELLED", name: "Cancelled" },
];

export const AccountTypeOptions = [
  { id: "BANK", name: "Bank" },
  { id: "CASH", name: "Cash" },
  { id: "DIGITAL_WALLET", name: "Digital Wallet" },
];

export const AccountTypes = {
  BANK: "BANK",
  CASH: "CASH",
  DIGITAL_WALLET: "DIGITAL_WALLET",
};

export const TransactionType = {
  PURCHASE: "PURCHASE",
  ADJUSTMENT: "ADJUSTMENT",
  RETURN: "RETURN",
  ORDER: "ORDER",
  RELOCATE: "RELOCATE",
  PACKAGE: "PACKAGE",
};

export const genderOptions = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "non_binary", name: "Non-Binary" }, // Người không thuộc vào hai giới tính nam hoặc nữ
  { id: "genderqueer", name: "Genderqueer" }, // Người có giới tính không theo chuẩn mực xã hội
  { id: "genderfluid", name: "Genderfluid" }, // Người thay đổi giới tính theo thời gian
  { id: "agender", name: "Agender" }, // Người không có giới tính
  { id: "bigender", name: "Bigender" }, // Người có hai giới tính hoặc thay đổi giữa hai giới tính
  { id: "pangender", name: "Pangender" }, // Người xác định mình với nhiều hoặc tất cả các giới tính
  { id: "two_spirit", name: "Two-Spirit" }, // Thuật ngữ bản địa Bắc Mỹ chỉ người có tinh thần và bản sắc của cả nam và nữ
  { id: "other", name: "Other" }, // Giới tính khác không được liệt kê
  { id: "prefer_not_to_say", name: "Prefer Not to Say" }, // Không muốn tiết lộ giới tính
];

export const UserRoles = {
  Admin: "Admin",
  User: "User",
  Customer: "Customer",
  Guest: "Guest",
};

export const periodOptions = [
  { name: "Today", id: "today" },
  { name: "This week", id: "this_week" },
  { name: "This month", id: "this_month" },
  { name: "This year", id: "this_year" },
  { name: "Customize", id: "custom" },
];

export const LoyalCustomerStatusOptions = [
  { id: "ACTIVATE", name: "Activate" },
  { id: "REMOVED", name: "Removed" },
];

export const PointEventTypeOptions = [
  { id: "REDEEM", name: "Redeem" },
  { id: "PLUS", name: "Plus" },
];

export const PointEventTypes = {
  REDEEM: "Redeem",
  PLUS: "Plus",
};

export const ChannelStatusOptions = [
  { id: "ACTIVE", name: "Active" },
  { id: "INACTIVE", name: "Inactive" },
  { id: "PENDING", name: "Pending" },
];

export const ChannelStatuses = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PENDING: "PENDING",
};

export const LanguageOptions = [
  { id: "vn", name: "Việt Nam" },
  { id: "en", name: "English" },
];
