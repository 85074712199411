import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime, convertSnakeCamel } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import {
  ActionGroup,
  ActionGroupOptions,
  Actions,
  ActionType,
  ActionTypeOptions,
  EventSource,
  EventSourceOption,
  FetchEventStatusOptions,
  RecordResponseCodeOptions,
  RecordResponseTypeOptions,
  RecordTypeOptions,
  RecordTypes,
} from "../../../../Components/constants/common";

import { listFetchEvent } from "../../../../store/actions";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ImageCustom from "../../../../Components/Common/ImageCustom";

const FetchEventList = (props) => {
  document.title = "Fetch Event List | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  const {
    fetchEvents,
    fetchEventsLoading,
    fetchEventsAction,
    fetchEventsSuccess,
    fetchEventsError,
    fetchEventsLimit,
    fetchEventsPage,
    fetchEventsTotal,
    fetchEventsMessage,
  } = useMemoizedSelector((state) => ({
    fetchEvents: state.FetchEvents.fetchEvents,
    fetchEventsAction: state.FetchEvents.action,
    fetchEventsMessage: state.FetchEvents.message,
    fetchEventsLoading: state.FetchEvents.loading,
    fetchEventsSuccess: state.FetchEvents.success,
    fetchEventsError: state.FetchEvents.error,
    fetchEventsLimit: state.FetchEvents.limit,
    fetchEventsPage: state.FetchEvents.page,
    fetchEventsTotal: state.FetchEvents.total,
  }));

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = ["channel", "action_type", "action_group", "status", "event_source", "event_time", "action"];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={fetchEvents} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="fetch-event-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Fetch Event List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"fetchEvents"}
                  searchPlaceHolder="Search fetch event..."
                  listFilter={[
                    {
                      id: "action_type",
                      type: "selectBox",
                      title: "Action Type",
                      dataOption: ActionTypeOptions,
                    },
                    {
                      id: "action_group",
                      type: "selectBox",
                      title: "Action Group",
                      dataOption: ActionGroupOptions,
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: FetchEventStatusOptions,
                    },
                    {
                      id: "connection_id",
                      type: "selectBox",
                      title: "Connection ID",
                      remote: true,
                      pathUrlLoad: "/connections/list",
                    },
                    {
                      id: "event_time",
                      type: "date",
                      title: "Event Time",
                    },
                    {
                      id: "event_source",
                      type: "selectBox",
                      title: "Event Source",
                      dataOption: EventSourceOption,
                    },
                  ]}
                  headers={headers}
                  contents={fetchEvents}
                  keyRow="updated_at"
                  limitPage={Number(fetchEventsLimit)}
                  currentPage={Number(fetchEventsPage) + 1}
                  totalItems={fetchEventsTotal}
                  loading={fetchEventsLoading && fetchEventsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  defaultPayload={{ limit: 20, sort_updated_at: "desc" }}
                  onCallData={(dataPayload) => {
                    dispatch(listFetchEvent(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Record Details",
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Channel"),
                          key: "channel",
                          dataIndex: "channel",
                          width: "90px",
                          render: (value, data, index) => {
                            const saleChannel = saleChannels.find((saleChannel) => saleChannel.key === value);
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={value} placement="left">
                                  <Link
                                    to={`/sync-records/${encodeURIComponent(data.id)}`}
                                    className="fw-medium d-block text-truncate link-primary"
                                  >
                                    <ImageCustom
                                      image={saleChannel?.logo}
                                      name={value}
                                      avatarSize={"avatar-xs"}
                                      isPreview={false}
                                    />
                                  </Link>
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Action Type"),
                          key: "action_type",
                          dataIndex: "action_type",
                          render: (value, data, index) => {
                            return (
                              <Link
                                to={`/fetch-events/${data.id}`}
                                className="fw-medium d-block text-truncate link-primary"
                              >
                                <Tooltip title={convertSnakeCamel(value) || "---"}>
                                  {convertSnakeCamel(value) || "---"}
                                </Tooltip>
                              </Link>
                            );
                          },
                        },
                        {
                          title: "Action group",
                          dataIndex: "action_group",
                          key: "action_group",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={convertSnakeCamel(value) || "---"}>
                                  {convertSnakeCamel(value) || "---"}
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Status",
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                        },
                        {
                          title: "Event Source",
                          dataIndex: "event_source",
                          key: "event_source",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={convertSnakeCamel(value) || "---"}>
                                  {convertSnakeCamel(value) || "---"}
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Event Time",
                          dataIndex: "event_time",
                          key: "event_time",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: t("Action"),
                          dataIndex: "action",
                          key: "action",
                          render: (value, row, index) => {
                            const actions = [
                              {
                                type: "view",
                                title: "View Sync Records List",
                                onClick: () => {
                                  // Log the fetch_event_id value before navigating
                                  console.log("fetch_event_id:", row.id); // This will log the value of fetch_event_id

                                  navigate(`/sync-records?fetch_event_id=${row.id}`, {
                                    state: { page: "fetch_event" },
                                  });
                                },
                              },
                            ];
                            return <ActionDropdown index={index} actions={actions} />;
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(FetchEventList);
