import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import {
  Actions,
  RecordResponseCodeOptions,
  RecordResponseTypeOptions,
  RecordTypeOptions,
  RecordTypes,
  SyncEventStatusOptions,
} from "../../../../Components/constants/common";
import { listInstalledChannel, listSaleChannel } from "../../../../store/actions";

import { listSyncRecord } from "../../../../store/actions";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ImageCustom from "../../../../Components/Common/ImageCustom";

const SyncRecordList = (props) => {
  document.title = "Sync Record List | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();

  const {
    syncRecords,
    syncRecordsLoading,
    syncRecordsAction,
    syncRecordsSuccess,
    syncRecordsError,
    syncRecordsLimit,
    syncRecordsPage,
    syncRecordsTotal,
    syncRecordsMessage,
  } = useMemoizedSelector((state) => ({
    syncRecords: state.SyncRecords.syncRecords,
    syncRecordsAction: state.SyncRecords.action,
    syncRecordsMessage: state.SyncRecords.message,
    syncRecordsLoading: state.SyncRecords.loading,
    syncRecordsSuccess: state.SyncRecords.success,
    syncRecordsError: state.SyncRecords.error,
    syncRecordsLimit: state.SyncRecords.limit,
    syncRecordsPage: state.SyncRecords.page,
    syncRecordsTotal: state.SyncRecords.total,
  }));
  useEffect(() => {
    dispatch(listSaleChannel({}));
  }, [dispatch]);
  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    "channel",
    "record_type",
    "fetch_event_id",
    "connection_id",
    "status",
    "created_at",
    "updated_at",
    "fetched_at",
    "finished_at",
    "published_at",
    "transformed_at",
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={syncRecords} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="sync-record-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Sync Record List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"syncRecords"}
                  searchPlaceHolder="Search sync record..."
                  listFilter={[
                    {
                      id: "record_type",
                      type: "selectBox",
                      title: "Record Type",
                      dataOption: RecordTypeOptions,
                    },
                    {
                      id: "connection_id",
                      type: "selectBox",
                      title: "Connection ID",
                      remote: true,
                      pathUrlLoad: "/connections/list",
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: SyncEventStatusOptions,
                    },
                    {
                      id: "fetch_event_id",
                      type: "default",
                      title: "Fetch event ID",
                      remote: true,
                      pathUrlLoad: "/flows/connections/list_fetch_events",
                    },
                  ]}
                  headers={headers}
                  contents={syncRecords}
                  keyRow="updated_at"
                  limitPage={Number(syncRecordsLimit)}
                  currentPage={Number(syncRecordsPage) + 1}
                  totalItems={syncRecordsTotal}
                  loading={syncRecordsLoading && syncRecordsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  defaultPayload={{ limit: 20, sort_updated_at: "desc" }}
                  onCallData={(dataPayload) => {
                    dispatch(listSyncRecord(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Record Details",
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Channel"),
                          key: "channel",
                          dataIndex: "channel",
                          width: "90px",
                          render: (value, data, index) => {
                            const saleChannel = saleChannels.find((saleChannel) => saleChannel.key === value);
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={value} placement="left">
                                  <Link
                                    to={`/sync-records/${encodeURIComponent(data.id)}`}
                                    className="fw-medium d-block text-truncate link-primary"
                                  >
                                    <ImageCustom
                                      image={saleChannel?.logo}
                                      name={value}
                                      avatarSize={"avatar-xs"}
                                      isPreview={false}
                                    />
                                  </Link>
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Fetch event ID",
                          dataIndex: "fetch_event_id",
                          key: "fetch_event_id",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={value}>{value || "---"}</Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Record Type",
                          dataIndex: "record_type",
                          key: "record_type",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={RecordTypes?.[value] ? t(RecordTypes[value]) : "---"}>
                                  {RecordTypes?.[value] ? t(RecordTypes[value]) : "---"}
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Connection ID",
                          dataIndex: "connection_id",
                          key: "connection_id",
                          render: (value, row, index) => {
                            return (
                              <div className="d-block text-truncate">
                                <Tooltip title={value}>{value || "---"}</Tooltip>
                              </div>
                            );
                          },
                        },
                        {
                          title: "Status",
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                        },
                        {
                          title: "Created At",
                          dataIndex: "created_at",
                          key: "created_at",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Updated at",
                          dataIndex: "updated_at",
                          key: "updated_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Fetched at",
                          dataIndex: "fetched_at",
                          key: "fetched_at",

                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Published at",
                          dataIndex: "published_at",
                          key: "published_at",

                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Transformed at",
                          dataIndex: "transformed_at",
                          key: "transformed_at",

                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Finished at",
                          dataIndex: "finished_at",
                          key: "finished_at",

                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SyncRecordList);
