import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Container, Row, CardHeader, Col, CardBody } from "reactstrap";
import "nouislider/distribute/nouislider.css";

import { formatDate1, formatVNDCurrency } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { PeriodOptions } from "../../../../Components/constants/common";

import { setConfigTour } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { Tooltip, Button, message } from "antd";
import { getBestSellingProducts, getReportProductSellingOverview } from "../../../../helpers/service_helper";
import SellOverviewChart from "../Chart/SellOverviewChart";
import AverageOverviewChart from "../Chart/AverageOverviewChart";
import Loader from "../../../../Components/Common/Loader";
import { CopyOutlined } from "@ant-design/icons";

const ProductReport = (props) => {
  const {
    router: { navigate, locations, params, t },
  } = props;
  document.title = `${t("Product Report List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const getBestSellingProductsData = async (data) => {
    try {
      setProductsLoading(true);
      const res = await getBestSellingProducts(data);
      setProducts(res);
    } catch (e) {
      console.error(String(e));
    } finally {
      setProductsLoading(false);
    }
  };

  const getDataChart = async (data) => {
    try {
      setLoading(true);
      const res = await getReportProductSellingOverview(data);
      setSellingOverview(res || []);
    } catch (e) {
      console.error(e);
      setSellingOverview([]);
    } finally {
      setLoading(false);
    }
  };

  const now = new Date();
  const year = now.getFullYear();
  const startOfThisYear = formatDate1(new Date(year, 0, 1));
  const endOfThisYear = formatDate1(new Date(year, 11, 31));

  const [loading, setLoading] = useState(false);
  const [sellingOverview, setSellingOverview] = useState([]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: t("Upload File"),
      description: t("Put your files here."),
      target: () => ref1.current,
    },
    {
      title: t("Save"),
      description: t("Save your changes."),
      target: () => ref2.current,
    },
  ];

  useEffect(() => {
    dispatch(
      setConfigTour({
        typeTour: "product",
        steps,
      }),
    );
    return () => {
      dispatch(
        setConfigTour({
          typeTour: "product",
          steps: [],
        }),
      );
    };
  }, [dispatch]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div className="card">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">{t("Product Report List")}</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    showSearch={true}
                    scrollHeight={500}
                    filterType={"product_reports"}
                    customSearchQuery="sku"
                    numberOfFilters={5}
                    searchPlaceHolder={"Search by SKU..."}
                    listFilter={[
                      {
                        id: "category.id",
                        type: "selectBox",
                        title: "Category",
                        remote: true,
                        pathUrlLoad: "product/categories",
                      },
                      {
                        id: "date",
                        type: "date",
                        title: "Date",
                      },
                      {
                        id: "period",
                        type: "selectOne",
                        title: `${t("Period")}`,
                        dataOption: PeriodOptions,
                      },
                      {
                        id: "location.id",
                        type: "selectBox",
                        title: "Location",
                        remote: true,
                        pathUrlLoad: "inventory/locations",
                      },
                    ]}
                    defaultPayload={{
                      date_from: startOfThisYear,
                      date_to: endOfThisYear,
                    }}
                    tableView={true}
                    defaultDateFilter={false}
                    headers={[
                      "name",
                      "images",
                      "category",
                      "sku",
                      "import_quantity",
                      "color",
                      "cost_price",
                      "selling_price",
                      "import_date",
                      "import_month",
                      "sold",
                      "remaining",
                      "turnover",
                      "discount",
                      "revenue",
                      "profit",
                      "unit_price",
                      "total_discount",
                      "total_sell_through",
                    ]}
                    contents={products}
                    loading={productsLoading}
                    onCallData={(dataPayload) => {
                      const location_ids = dataPayload["location.id"]
                        ? dataPayload["location.id"].includes(",")
                          ? dataPayload["location.id"].split(",")
                          : [dataPayload["location.id"]]
                        : undefined;
                      const category_ids = dataPayload["category.id"]
                        ? dataPayload["category.id"].includes(",")
                          ? dataPayload["category.id"].split(",")
                          : [dataPayload["category.id"]]
                        : undefined;
                      const date_from = dataPayload?.date_from ? dataPayload.date_from : startOfThisYear;
                      const date_to = dataPayload?.date_to ? dataPayload.date_to : endOfThisYear;

                      const newPayload = {
                        date_from,
                        date_to,
                        period: dataPayload.period,
                        location_ids,
                        category_ids,
                        sku: dataPayload.sku,
                      };
                      getDataChart(newPayload);
                      getBestSellingProductsData({ ...newPayload, period: "custom" });
                    }}
                    showPagination={false}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Product details"),
                        key: "detail-product",
                        children: [
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            render: (value, row, index) => {
                              return (
                                <Tooltip title={t(row.name)}>
                                  <h5 className="fs-14 mb-1">
                                    <Link
                                      to={`/report/products/${row.id}`}
                                      className="text-truncate-two-lines link-primary"
                                    >
                                      {t(row.name)}
                                    </Link>
                                  </h5>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Image"),
                            dataIndex: "images",
                            key: "images",
                            render: (value, row, index) => {
                              return (
                                <ImageCustom image={row.images?.[0]?.url} name={row.name} avatarSize={"avatar-sm"} />
                              );
                            },
                          },
                          {
                            title: t("Category"),
                            dataIndex: "category",
                            key: "category",
                            sortKey: "category.name",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: "SKU",
                            dataIndex: "sku",
                            key: "sku",
                            render: (value, row, index) => {
                              const handleCopy = () => {
                                navigator.clipboard.writeText(value);
                                message.success(t("SKU copied to clipboard"));
                              };
                              return (
                                <div className="d-flex align-items-center gap-3">
                                  <span>{value}</span>
                                  <Button
                                    variant="outlined"
                                    icon={<CopyOutlined />}
                                    onClick={handleCopy}
                                    size="middle"
                                    className="p-0"
                                  />
                                </div>
                              );
                            },
                          },
                          {
                            title: t("Import Quantity"),
                            dataIndex: "import_quantity",
                            key: "import_quantity",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || 0}</div>;
                            },
                          },
                          {
                            title: t("Color"),
                            dataIndex: "color",
                            key: "color",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || "---"}</div>;
                            },
                          },
                          {
                            title: t("Cost Price"),
                            dataIndex: "cost_price",
                            key: "cost_price",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Selling Price"),
                            dataIndex: "selling_price",
                            key: "selling_price",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Import Date"),
                            dataIndex: "import_date",
                            key: "import_date",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatDate1(value) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Import Month"),
                            dataIndex: "import_month",
                            key: "import_month",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || "---"}</div>;
                            },
                          },
                          {
                            title: t("Sold"),
                            dataIndex: "sold",
                            key: "sold",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || 0}</div>;
                            },
                          },
                          {
                            title: t("Remaining"),
                            dataIndex: "remaining",
                            key: "remaining",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || 0}</div>;
                            },
                          },
                          {
                            title: t("Turnover"),
                            dataIndex: "turnover",
                            key: "turnover",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Discount"),
                            dataIndex: "discount",
                            key: "discount",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Revenue"),
                            dataIndex: "revenue",
                            key: "revenue",
                            render: (value, row, index) => {
                              return (
                                <div className="text-truncate-two-lines">{formatVNDCurrency(value?.value || 0)}</div>
                              );
                            },
                          },
                          {
                            title: t("Profit"),
                            dataIndex: "profit",
                            key: "profit",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Unit Price"),
                            dataIndex: "unit_price",
                            key: "unit_price",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{formatVNDCurrency(value || 0)}</div>;
                            },
                          },
                          {
                            title: t("Total Discount"),
                            dataIndex: "total_discount",
                            key: "total_discount",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{Math.round(value || 0)}%</div>;
                            },
                          },
                          {
                            title: t("Total Sell Through"),
                            dataIndex: "total_sell_through",
                            key: "total_sell_through",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{value || 0}%</div>;
                            },
                          },
                        ],
                      },
                    ]}
                  >
                    <Row>
                      <Col lg={6} className="h-100 my-3">
                        {loading ? (
                          <div className="py-3">
                            <Loader />
                          </div>
                        ) : (
                          <SellOverviewChart
                            dataColors='["--color-primary", "--color-success"]'
                            sellingOverview={sellingOverview}
                            t={t}
                          />
                        )}
                        <h5 className="card-title mb-0 text-truncate text-center">{t("Selling through")}</h5>
                      </Col>
                      <Col lg={6} className="h-100 my-3">
                        {loading ? (
                          <div className="py-3">
                            <Loader />
                          </div>
                        ) : (
                          <AverageOverviewChart
                            dataColors='["--color-primary", "--color-success"]'
                            sellingOverview={sellingOverview}
                            t={t}
                          />
                        )}
                        <h5 className="card-title mb-0 text-truncate text-center">
                          {t("Average discount")}/{t("Average sale price")}
                        </h5>
                      </Col>
                    </Row>
                  </TableContainerCustom>
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ProductReport);
